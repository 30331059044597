import Image from "next/image";
import style from "./style.module.css";
import whatsapp from "../../assets/images/whatsapp.png";

const Whatsapp=()=>{
    return(
        <>
        <div className="d-none d-md-block">
        <a className={`${style.linkfont}`} href="https://web.whatsapp.com/send?phone=919310358130&text=Hello!" target="blank"><button className={`${style.button}`} id={style.fixedbutton}><Image src={whatsapp} className="img-fluid align-middle me-2" alt="Whatsapp" />How can we help you?</button></a>
        </div>

        <div className="d-block d-md-none">
        <a className={`${style.linkfont}`} href="https://wa.me/+919310358130" target="blank"><button className={`${style.button}`} id={style.fixedbutton}><Image src={whatsapp} className="img-fluid align-middle me-2" alt="Whatsapp" />How can we help you?</button></a>
        </div>
        </>
    )
}
export default Whatsapp;