import '../styles/globals.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import dynamic from "next/dynamic";
import Head from 'next/head';
import Whatsapp from '../components/whatsapp-button';
import DelhiWhatsapp from '../components/delhi-whatsapp-button';
//const Whatsapp = dynamic(() => import("../components/whatsapp-button"));
//const DelhiWhatsapp = dynamic(() => import("../components/delhi-whatsapp-button"));
import { useRouter } from 'next/router';
import 'bootstrap-icons/font/bootstrap-icons.css';


export default function App({ Component, pageProps }) {

  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
  }, []);

  useEffect(() => {
    console.log(router.pathname.includes("/delhi/"), router.pathname);
    if (
      window.location.href.includes("/delhi/")
    ) {
      setIsLoading(true);
      console.log(window.location.href, "whatsapp");
    }
    else if (
      window.location.href.includes("/sch-test/")
    ) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
    }
  }, [router.pathname]);


  return (
    <>
      {!isLoading &&
        <Head>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-7BBP512TZQ"></script>
          <script>
            {`
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                        
                          gtag('config', 'G-7BBP512TZQ');
                          `};
          </script>

          <script type="text/javascript">
                {`(function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "npo1kw2swe");
                `};
         </script>
          <script>
          {`
            !function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1448994685363415');
fbq('track', 'PageView');
            `};
          </script>
        </Head>
      }
      {isLoading &&

        <Head>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-2ST7ZJ1RE5"></script>
          <script>{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', 'G-2ST7ZJ1RE5');
            `};
            </script>
            


        </Head>
      }


      <title>Shankar IAS Academy</title>

      <Component {...pageProps} />
      <ToastContainer
        theme='dark'
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
      />
      
      {!isLoading &&
       <Whatsapp /> 
      }
      {isLoading &&
        <DelhiWhatsapp />
      }
    </>)
}
